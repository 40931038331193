import React, { useState, useEffect } from 'react';

const CounterNum = () => {
  const [count, setCount] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      if (count>=0)
        setCount(count => count - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  return (
    <>{count}</>
  )
}

export default CounterNum;
