import './App.css';
import { Image, Space} from 'antd'
import { useEffect } from 'react';
import CounterNum from './CounterNum';
import React from 'react'

const App = () => {
    useEffect(() => {
        const timeout = setTimeout(() => {
          // 👇️ redirects to an external URL
          window.location.replace(process.env.REACT_APP_GEN_AI_REDIRECT_URL);
        }, 10000);
    
        return () => clearTimeout(timeout);
      }, []);    
  return (
    <div className="layout">
    <div className='main'>
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topLeft">
                    <div className="logo">
                        <Space>
                            <span style={{ color: '#fff' }}>
                                DuPont GenAI-Chat
                            </span>
                        </Space>
                    </div>

                </div>
                <div className="topRight">
                    <Image
                        height={24}
                        src='../assets/DuPont_tm_w.svg'
                        preview={false}
                    />
                </div>
            </div>
        </div>
        <div className="layout__content">
            <div className="layout__content-main">
                <div style={{ position:'fixed', top:'50%', textAlign:'center',width:'100%' }}>
                    <div style={{ lineHeight: 2 }}>
                        <h3>The URL for the DuPont GenAI has changed.<a href= {process.env.REACT_APP_GEN_AI_REDIRECT_URL}>Click here</a> to go to the new URL or<br/><p>wait for  <CounterNum/> sec to redirect.</p></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
)
}

export default App
